import { createBrowserRouter } from 'react-router-dom';
import { Login, Main, Consultations, Profile, Presentation } from '../pages';
import { AdminLogin, ClientLogin, AddOns, Discounts, Locations, PracticeInfo, Treatments, Users, Info, ResetPassword, PatientInfo, PresentationInfo, Contract, Payment, PaymentForm, MonthlyPaymentQuestion, CancelPayment, SuccessPage } from '../components';
import MonthlyPaymentForm from '../components/MonthlyPaymentForm/MonthlyPaymentForm'
import MonthlyPaymentContract from '../components/Contract/MonthlyPaymentContract'
import PatientProfile from '../components/PatientProfile/PatientProfile'
import ThanksPage from '../components/ThanksPage/ThanksPage'
import ProfileReset from '../pages/Profile/ProfileReset'
import ProfileCode from '../pages/Profile/ProfileCode'

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
		children: [
			{
				path: '/info',
				element: <Info />,
				children: [
					{
						path: '/info/practice-information',
						element: <PracticeInfo />
					},
					{
						path: '/info/treatments',
						element: <Treatments />
					},
					{
						path: '/info/add-ons',
						element: <AddOns />
					},
					{
						path: '/info/discounts',
						element: <Discounts />
					},
					{
						path: '/info/locations',
						element: <Locations />
					},
					{
						path: '/info/users',
						element: <Users />
					}
				]
			},
			{
				path: '/consultations',
				element: <Consultations />,
				children: [
					{
						path: '/consultations/:id',
						element: <PatientInfo />,
						children: [
							{
								path: '/consultations/:id/present',
								element: <Presentation />
							}
						]
					}
				]
			},
			{
				path: '/profile',
				element: <Profile />,
				children: [
					{
						path: '/profile',
						element: <ProfileReset />
					},
					{
						path: '/profile/reset',
						element: <ProfileCode />
					},
				]
			},
			{
				path: '/reset-password',
				element: <ProfileReset />
			},
		]
	},
	{
		path: '/present/:id/:date',
		element: <Presentation />,
		children: [
			{
				path: '/present/:id/:date/:treatmentId',
				element: <PresentationInfo />,
				children: [
					{
						path: '/present/:id/:date/:treatmentId/contract',
						element: <Contract />
					}
				]
			}
		]
	},
	{
		path: '/contract/:id/:date/:treatmentId/',
		element: <Contract />
	},
	{
		path: '/payment/:id/:date/:treatmentId',
		element: <Payment />
	},
	{
		path: '/payment-form/:id/:date/:treatmentId',
		element: <PaymentForm />
	},
	// {
	// 	path: '/form/:id/:date/:treatmentId',
	// 	element: <FormWrapper />
	// },
	{
		path: '/success/:id/:date/:treatmentId',
		element: <SuccessPage />
	},
	{
		path: '/thank-you/:id/:date/:treatmentId',
		element: <ThanksPage />
	},
	{
		path: '/monthly-payment/:id/:date/:treatmentId',
		element: <MonthlyPaymentQuestion />
	},
	{
		path: '/monthly-payment-form/:id/:date/:treatmentId',
		element: <MonthlyPaymentForm />
	},
	{
		path: '/monthly-payment-contract/:id/:date/:treatmentId',
		element: <MonthlyPaymentContract />
	},
	{
		path: '/patient-profile/:id/:date/:treatmentId',
		element: <PatientProfile />
	},
	{
		path: '/payment-error',
		element: <CancelPayment />
	},
	{
		path: '/login',
		element: <Login />,
		children: [
			{
				path: '/login/admin',
				element: <AdminLogin />
			},
			{
				path: '/login/:id',
				element: <ClientLogin />
			},
			{
				path: '/login/reset',
				element: <ResetPassword />
			}
		]
	}
]);
